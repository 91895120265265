import { render, staticRenderFns } from "./Opportunity-Detail.vue?vue&type=template&id=7c08c167&scoped=true"
import script from "./Opportunity-Detail.vue?vue&type=script&lang=js"
export * from "./Opportunity-Detail.vue?vue&type=script&lang=js"
import style0 from "./Opportunity-Detail.vue?vue&type=style&index=0&id=7c08c167&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c08c167",
  null
  
)

export default component.exports
<template>
	
	<v-menu
		ref="menu"
		v-model="menuVisible"
		:close-on-content-click="false"
		:close-on-click="false"
		:return-value.sync="date"
		transition="slide-y-transition"
		bottom
		offset-y
		left
		persistent
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn min-width="20" height="20" icon plain @click.stop="toggleMenu" v-bind="attrs" v-on="on">
				<v-icon color="blue" size="18">mdi-calendar-edit</v-icon>
			</v-btn>
		</template>
		 

		<v-date-picker v-model="date" no-title show-adjacent-months scrollable>
			<v-spacer></v-spacer>
			<v-btn class="white--text" depressed color="blue darken-4" tile @click="saveDate"> Save </v-btn>
			<v-btn depressed tile @click="closeMenu"> Close </v-btn>
		</v-date-picker>
	</v-menu>
</template>
  
  <script>
  import { POST } from "@/core/services/store/request.module";
  import { SET_MESSAGE } from "@/core/services/store/common.module";

export default {
	name: "UpdateReminderMenu",
	data() {
		return {
			date: null,
			menuVisible: this.editReminder,
		};
	},
	props: {
		editReminder: {
			type: Boolean,
			default: false,
		},
		id: {
			type: Number,
			
		},
		
	},
	watch: {
		// editReminder(newVal) {
		// 	this.menuVisible = newVal;
		// },
		menuVisible(newVal) {
			this.$emit("update:editReminder", newVal);
		},
	},
	methods: {
		toggleMenu() {
			this.menuVisible = true;
		},
		saveDate() {
			const _this = this;
				this.$store
					.dispatch(POST, {
						url: `update-reminder`,
						data: {
							id: this.id,
							date: this.date,
						},
					})
					.then(() => {
						//   _this.reservation_status = data;
						_this.$store.commit(SET_MESSAGE, [{ model: true, message: "Updated Successfully." }]);
						this.closeMenu();
						this.$emit("success", true);
						

					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {});
			
		},
		
		closeMenu() {
			this.menuVisible = false;
		},
	},
};
</script>
  
<template>
	<div>
		<DetailTemplate :custom-class="'opportunity-detail detail-page'">
			<template v-slot:header-topbar>
				<div class="py-2 border-bottom">
					<!-- {{all_count_meeting}} -->

					<div class="d-flex align-center">
						<div class="d-flex align-center pb-1">
							<div class="font-weight-bold text-h4 text-capitalize" style="color: #0d47a1">
								{{ opportunityData.name }}
							</div>
							<v-icon size="xx-small" class="mx-2" color="grey">mdi-circle</v-icon>
							<div class="text-h5 font-weight-bold">{{ formatMoney(opportunityData.amount) }}</div>
						</div>
						<v-spacer></v-spacer>
						<v-chip
							class="ml-1 px-2 text-capitalize"
							color="t"
							outlined
							small
							v-if="opportunityData && opportunityData.barcode"
							>{{ opportunityData.barcode }}
						</v-chip>
						<template>
							<div :key="rerenderKey">
								<v-btn
									v-if="opportunityData.status == 'accept'"
									small
									rounded
									color="green darken-4 text-white"
									class="ml-2"
									depressed
									>Accepted
								</v-btn>
								<v-btn
									v-else-if="opportunityData.status != 'accept' && opportunityData.status != 'reject'"
									@click="changeColor('accept')"
									color="green darken-4 text-white"
									class="ml-2"
									depressed
									tile
									>Accepted
								</v-btn>
								<v-btn
									v-if="opportunityData.status == 'reject'"
									small
									rounded
									color="red darken-4 text-white"
									class="ml-2"
									depressed
									>Rejected
								</v-btn>
								<v-btn
									v-else-if="opportunityData.status != 'accept' && opportunityData.status != 'reject'"
									@click="changeColor('reject')"
									color="red darken-4 text-white"
									class="ml-2"
									depressed
									tile
									>Rejected
								</v-btn>
								<v-btn
									v-if="opportunityData.status == 'reject' || opportunityData.status == 'accept'"
									@click="changeColor('reopen')"
									outlined
									color="grey"
									class="ml-2"
									depressed
									tile
									>Reopen
								</v-btn>
							</div>
						</template>

						<!-- <v-menu offset-y left bottom content-class="elevation-3 border" rounded="0" max-width="180px">


						<v-menu offset-y left bottom content-class="elevation-3 border" rounded="0" max-width="180px">

							<template v-slot:activator="{ on, attrs }">
								<v-btn color="blue darken-4 text-white" v-on="on" v-bind="attrs" class="ml-2" depressed tile
									><v-icon left small class="mdi-rotate-180">mdi-more</v-icon>More
								</v-btn>
							</template>
							<v-list class="pa-2">
								<v-list-item class="px-2" v-on:click="open_opportunity_drawer('Clone')">
									<v-list-item-title> Clone</v-list-item-title>
								</v-list-item>
								
							</v-list>
						</v-menu> -->
						<v-btn depressed tile class="my-auto ms-2" v-on:click="goBackData()">
							<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
							back
						</v-btn>
					</div>
					<div class="d-flex align-center">
						<span class="text-capitalize">
							<v-icon small color="orange">mdi-crown</v-icon>
							{{ opportunityData?.company?.company_name }}
						</span>
						<v-icon size="xx-small" class="mx-2" color="grey">mdi-circle</v-icon>

						<span>Created date :&nbsp;{{ formatDateTime(opportunityData.added_at) }}</span>

						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span  v-bind="attrs" v-if="opportunityData && opportunityData.created_date">
									<v-chip class="text-capitalize" color="blue white--text" x-small label
										><v-icon class="me-1" x-small>mdi-clock-outline</v-icon>01/03/2024 07:02 PM</v-chip
									>
								</span>
							</template>

							<span>Created date :&nbsp; {{ formatDateTime(opportunityData.added_at) }}</span>
						</v-tooltip>
						<v-icon size="xx-small" class="mx-2" color="grey">mdi-circle</v-icon>
						<span>Last contacted :&nbsp; </span>
						<span
							>{{ formatDateTime(opportunityData.updated_at)
							}}<b class="blue--text ms-1">({{ diffInDays }})</b></span
						>
					</div>
					<div class="step-status mt-2">
						<v-tabs
							v-model="statusTab"
							background-color="transparent"
							grow
							color="green"
							active-class="green darken-4 text-white"
							hide-slider
						>
							
								<template >
									<v-tab
										@click="updateStatus('not-contacted')"
										
										
										:class="statusTab <= 4 ? activeTabClass : ''"
									>
										Not Contacted ({{ notcontactedstatus }})</v-tab
									>
								</template>
							
						
								<template>
									<v-tab
										@click="updateStatus('follow-up')"
									
										
										:class="statusTab <= 4 && statusTab >= 1 ? activeTabClass : ''"
									>
										Follow up ({{ followstatus }})</v-tab
									>
								</template>
							
							
						
								<template >
									<v-tab
										@click="updateStatus('meeting')"
										
										v-on="on"
										:class="statusTab <= 4 && statusTab >= 2 ? activeTabClass : ''"
									>
										Meeting ({{ meetingstatus }})</v-tab
									>
								</template>
							
							
						
								<template >
									<v-tab
										@click="updateStatus('quotation')"
										
										v-on="on"
										:class="statusTab <= 4 && statusTab >= 3 ? activeTabClass : ''"
									>
										Quotation ({{ quotationstatus }})
									</v-tab>
								</template>
								
						
						
								<template>
									<v-tab
										@click="updateStatus('negotiation')"
									
										v-on="on"
										:class="statusTab <= 4 && statusTab >= 4 ? activeTabClass : ''"
									>
										Negotiation ({{ negotiationstatus }})</v-tab
									>
								</template>
								
						
						</v-tabs>
					</div>
				</div>
			</template>
			<template v-slot:sidebar-detail>
				<div class="listing-sidebar">
					<v-col md="12">
						<v-hover v-slot="{ hover }">
							<div class="p-10 text-center">
								<v-progress-circular
									indeterminate
									:size="100"
									:width="10"
									color="cyan"
									v-if="primaryLoader"
								></v-progress-circular>
								<v-avatar size="140" v-else>
									<v-img
										max-height="140px"
										max-width="140px"
										:src="
											company_logo_url
												? company_logo_url
												: 'https://fsm-v3.businessthrust.com/media/default/no-profile-image.png'
										"
										class="custom-grey-border margin-auto custom-border-radius-50"
									>
									</v-img>
								</v-avatar>
								<template v-if="getPermission('opportunity:update')">
									<div class="engineer-image-action" style="position: relative; top: -30px; right: -45px">
										<v-btn
											color="blue darken-4 text-white"
											class="mr-2 mt-2 rounded-circle"
											depressed
											x-small
											rounded
											fab
											tile
											v-on:click="selectImage"
											><v-icon small>mdi-image-plus</v-icon>
										</v-btn>

										<div class="d-none">
											<v-file-input
												v-model.trim="rawImage"
												ref="uploadImage"
												accept="image/png, image/jpeg, image/bmp"
											></v-file-input>
										</div>
									</div>
								</template>
							</div>
						</v-hover>
					</v-col>
					<!-- <div class="text-center mb-3 pt-4">
						<v-avatar color="grey lighten-3" size="70">
							<v-img src="https://fsm-v3.businessthrust.com/media/default/no-profile-image.png"></v-img>
						</v-avatar>
					</div> -->

					<div class="d-flex justify-center mt-2 mb-1">
						<div class="detail-label" style="min-width: 100px">Product Type : &nbsp;</div>
						<div class="value">
							<template
								v-if="opportunityData && opportunityData.leadproduct && opportunityData.leadproduct.length"
							>
								<div class="d-flex align-center flex-wrap">
									<span v-for="(product, index) in opportunityData.leadproduct" :key="index">
										<v-chip

											class="text-capitalize mt-2 me-1"
											:color="product?.product?.status_color"

											label
											v-if="product && product?.product?.text"
										>
											{{ product?.product?.text }}
										</v-chip>
										<em class="text-muted" v-else> no product </em>
									</span>
								</div>
							</template>
							<em class="text-muted" v-else> no product data </em>
						</div>
					</div>
					<div class="d-flex justify-center mb-3">
						<div class="detail-label">Opportunity : &nbsp;</div>
						<div class="value">
							<v-chip
								class="mr-1 my-1 px-2 text-capitalize"
								color="orange white--text"
								x-small
								label
								v-if="opportunityData && opportunityData.opportunity"
								>{{ opportunityData.opportunity }}
							</v-chip>
							<em class="text-muted" v-else> no Opportunity </em>
						</div>
					</div>
					<div class="d-flex justify-center mb-1" v-if="opportunityData && opportunityData.meeting_date">
						<div class="detail-label">Meeting Date : &nbsp;</div>
						<div class="value">
							<b>{{ opportunityData.meeting_date }}</b>
						</div>
						<!-- <em class="text-muted" v-else>no meeting date</em> -->
					</div>
					<div class="d-flex justify-center mb-3" v-if="opportunityData && opportunityData.meeting_time">
						<div class="detail-label">Meeting Time : &nbsp;</div>
						<div class="value">
							<b>{{ opportunityData.meeting_time }}</b>
						</div>
						<!-- <em class="text-muted" v-else>no meeting time</em> -->
					</div>
					<div class="d-flex justify-center mb-1">
						<div class="detail-label">Reminder Date : &nbsp;</div>
						<div class="value">
							<template v-if="single_reminder && single_reminder.start_date">
								<b v-if="color1 == 1"
									><span style="color: red">{{ formatDate(single_reminder?.start_date) }}</span>
								</b>
								<b v-else>{{ formatDate(single_reminder?.start_date) }}</b>
								<UpdateReminderMenu :edit-reminder.sync="editReminder" :id=single_reminder.id v-on:close="editReminder = false" v-on:success="findOpportunity" UpdateReminderMenu/>
							</template>
							<em v-else class="text-muted"> no reminder </em>
						</div>
					</div>

					<table class="w-100 side-table mt-4">
						<tr>
							<td colspan="3">
								<div class="font-level-3-bold pa-1" style="background-color: #f1e8e8">Related Contact</div>
							</td>
						</tr>
						<tr>
							<td style="width: 95px" class="py-1">Contact</td>
							<td align="center" class="py-1" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500 text-capitalize" v-if="opportunityData && opportunityData.contact">
									{{ opportunityData.contact.salutation }} {{ opportunityData.contact.contact_name }}
								</span>
								<em class="text-muted" v-else>No contact name</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Email</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="opportunityData && opportunityData.contact_email">
									<v-icon small>mdi-email</v-icon>{{ opportunityData.contact_email }}
								</span>
								<em class="text-muted" v-else>No email</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Mobile</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="opportunityData && opportunityData.contact_phone">
									<v-icon small>mdi-cellphone</v-icon>{{ opportunityData.contact_phone }}
								</span>
								<em class="text-muted" v-else>No number</em>
							</td>
						</tr>
					</table>
					<table class="w-100 side-table mt-4">
						<tr>
							<td colspan="3">
								<div class="font-level-3-bold pa-1" style="background-color: #f1e8e8">Related Company</div>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Company</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500 text-capitalize" v-if="opportunityData && opportunityData.company">
									{{ opportunityData.company.company_name }}
								</span>
								<em class="text-muted" v-else>No company name</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Email</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="opportunityData && opportunityData.company_email">
									<v-icon small>mdi-email</v-icon>{{ opportunityData.company_email }}
								</span>
								<em class="text-muted" v-else>No email</em>
							</td>
						</tr>
						<tr>
							<td class="py-1" style="width: 95px">Phone</td>
							<td class="py-1" align="center" style="width: 10px">:</td>
							<td class="py-1">
								<span class="fw-500" v-if="opportunityData && opportunityData.company_phone">
									<v-icon small>mdi-phone</v-icon>{{ opportunityData.company_phone }}
								</span>
								<em class="text-muted" v-else>No number</em>
							</td>
						</tr>
					</table>
				</div>
			</template>
			<template v-slot:body>
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="px-3 py-2 flex-wrap">
							<div class="flex-grow-11">
								<v-tabs
									v-model="assetTab"
									background-color="transparent"
									color="blue"
									class="custom-tab-transparent w-100"
									active-class="blue darken-4 text-white"
									hide-slider
								>
									<v-tab href="#activity"><v-icon small left>mdi-wrench-outline</v-icon>Activity</v-tab>
									<v-tab href="#overview"><v-icon small left>mdi-note-plus-outline</v-icon>Overview</v-tab>
									<v-tab href="#meeting" @change="handleTabChange('meeting')">
										<v-icon small left>mdi-timeline-clock-outline</v-icon>Meeting ({{
											all_count_meeting[0]?.meetings_all
										}})</v-tab
									>
									<v-tab href="#reminder" @change="handleTabChange('reminder')">
										<v-icon small left>mdi-alarm-check</v-icon>Reminder({{
											reminderCounting?.all_reminders_count
										}})</v-tab
									>
									<v-tab href="#stage" @change="handleTabChange('stage')">
										<v-icon small left>mdi-history</v-icon>Stage</v-tab
									>
									<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon>Files </v-tab>
								</v-tabs>
							</div>
						</v-layout>
					</div>
					<div class="tab-layout-content">
						<v-tabs-items v-model="assetTab">
							<v-tab-item value="activity">
								<ActivityTabNew :logs="logData" :heading="opportunityData.name"></ActivityTabNew>
								<v-row v-if="false">
									<v-col md="8"> </v-col>
									<v-col md="6">
										<div v-if="logData && logData.length">
											<v-timeline dense>
												<v-timeline-item
													color="blue darken-4"
													small
													v-for="(logs, index) in logData"
													:key="index"
												>
													<!-- <div > -->
													<ActivityTab :open_details="false" :log="logs" title="Opportunity"></ActivityTab>
													<!-- </div> -->
												</v-timeline-item>
											</v-timeline>
										</div>
										<template v-else>
											<p class="m-0 row-not-found text-center">
												<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
												Uhh... There are no activity at the moment.
											</p>
										</template>
									</v-col>
								</v-row>
							</v-tab-item>
							<v-tab-item value="overview">
								<v-row class="">
									<v-col md="8">
										<div class="border mb-3">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Description</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="opportunityData.description">
													{{ opportunityData.description }}
												</template>
												<template v-else>
													<em class="text-muted">No description</em>
												</template>
											</div>
										</div>
										<div class="border mb-3">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Internal Remark</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="opportunityData.remark">
													{{ opportunityData.remark }}
												</template>
												<template v-else>
													<em class="text-muted">No Internal Remark</em>
												</template>
											</div>
										</div>
									</v-col>
									<v-col md="4">
										<div class="border mb-3">
											<div class="card--header d-flex align-items-center border-bottom pa-2">
												<div class="font-level-3-bold">Address</div>
												<v-spacer></v-spacer>
											</div>
											<div class="card--body d-flex align-items-center pa-2">
												<template v-if="opportunityData && opportunityData.address">
													<div class="d-flex align-baseline">
														<v-icon class="me-1" small>mdi-map</v-icon>72 Bendemeer Rd,<br />
														#07-03 Luzerne S(),<br />
														28 Lor 23 Geylang 00074,<br />
														Singapore,<br />
														11002
													</div>
												</template>
												<template v-else>
													<em class="text-muted">No address</em>
												</template>
											</div>
											<div class="google-map px-2 y-3 text h5">map</div>
										</div>
									</v-col>
								</v-row>
							</v-tab-item>
							<v-tab-item value="meeting">
								<v-layout class="pa-2 border-light-grey min-height-40px">
									<v-flex class="font-level-3-bold my-auto">
										<v-icon color="blue darken-4" left>mdi-timeline-clock-outline</v-icon>
										Meeting
									</v-flex>
									<!-- <v-flex class="text-right">
										<v-btn
											v-on:click="open_meeting_drawer('Create')"
											color="blue darken-4 text-white"
											class="ml-2"
											depressed
											tile
											><v-icon small left>mdi-plus</v-icon>Create Meeting</v-btn
										>
									</v-flex> -->
								</v-layout>
								<v-layout class="px-3 py-2 flex-wrap meeting_tab">
									<div class="flex-grow-11">
										<v-tabs
											v-model="meetingTab"
											background-color="transparent"
											color="blue"
											class="custom-tab-transparent w-100"
											hide-slider
										>
											<v-tab class="blue-tab" href="#all" v-on:click="getMeeting(10)">
												<v-icon small left>mdi-file-chart-outline</v-icon>All ({{
													all_count_meeting[0]?.meetings_all
												}})
											</v-tab>
											<v-tab class="grey-tab" href="#upcoming" v-on:click="getMeeting(4)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Upcoming ({{
													all_count_meeting[0]?.upcoming_meetings
												}})
											</v-tab>
											<v-tab class="blue-tab2" href="#postpond" v-on:click="getMeeting(3)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Postpond ({{
													all_count_meeting[0]?.postpone_meetings
												}})
											</v-tab>
											<v-tab class="green-tab" href="#completed" v-on:click="getMeeting(1)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Completed ({{
													all_count_meeting[0]?.completed_meetings
												}})
											</v-tab>
											<v-tab class="red-tab" href="#cancelled" v-on:click="getMeeting(2)">
												<v-icon small left>mdi-file-chart-outline</v-icon>Cancelled ({{
													all_count_meeting[0]?.cancel_meetings
												}})
											</v-tab>
										</v-tabs>
									</div>
								</v-layout>
								<v-tabs-items v-model="meetingTab">
									<v-tab-item value="all">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="upcoming">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="cancelled">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="postpond">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
									<v-tab-item value="completed">
										<MeetingTabNew
											:meetingData="meetingData"
											:key="rerenderKey"
											v-on:editMeeting="editMeeting($event)"
											v-on:editTitle="open_meeting_drawer($event)"
										></MeetingTabNew>
									</v-tab-item>
								</v-tabs-items>
							</v-tab-item>
							<v-tab-item value="reminder">
								<!-- {{reminderCounting.upcoming_reminders_count}} -->
								<v-layout class="px-4 py-2 reminder_tab">
									<v-tabs
										background-color="transparent"
										color="blue"
										class="custom-tab-transparent w-100"
										active-class="blue darken-4 text-white"
										hide-slider
										v-model="reminderTabs"
									>
										<v-tab class="red-tab" href="#overdue" v-on:click="getReminder(1)">
											<v-icon small left>mdi-alarm-check</v-icon>Overdue ({{
												reminderCounting && reminderCounting.overdue_reminders_count
													? reminderCounting.overdue_reminders_count
													: 0
											}})</v-tab
										>
										<v-tab class="blue-tab" href="#upcoming" v-on:click="getReminder(2)">
											<v-icon small left>mdi-alarm-check</v-icon>upcoming ({{
												reminderCounting && reminderCounting.upcoming_reminders_count
													? reminderCounting.upcoming_reminders_count
													: 0
											}})
										</v-tab>
										<v-tab class="green-tab" href="#complate" v-on:click="getReminder(3)">
											<v-icon small left>mdi-alarm-check</v-icon>Completed ({{
												reminderCounting && reminderCounting.completed_reminders_count
													? reminderCounting.completed_reminders_count
													: 0
											}})</v-tab
										>
									</v-tabs>
								</v-layout>
								<div class="tab-layout-content px-4">
									<v-tabs-items v-model="reminderTabs">
										<v-tab-item value="overdue">
											<div class="reminder-rows-container" v-if="false">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTabNew
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccess="refreshReminder"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTabNew>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no overdue reminders at the moment.
													</p>
												</template>
											</div>
											<div class="reminder-listing">
												<div class="overflow-y" style="max-height: calc(100vh - 245px)">
													<table
														width="100%"
														class="listing-table table-head-sticky"
														style="border-spacing: 0; border-collapse: initial"
													>
														<thead>
															<tr>
																<th class="p-2 light-blue-bg" width="300">Time</th>
																<th class="p-2 light-blue-bg" width="350">Title</th>
																<th class="p-2 light-blue-bg">Description</th>
																<th class="p-2 light-blue-bg text-center" width="200">Action</th>
															</tr>
														</thead>
														<tbody v-if="reminderData">
															<template v-for="(row, index) in reminderData">
																{{ row.start_date }}

																<tr :key="'date-' + index" v-if="!index == ''" :class="index ? 'active_class' : ''">
																	<td colspan="4" class="p-2 border-top-light-grey">
																		<div>
																			<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon
																			><span
																				class="font-level-2-bold text-capitalize"
																				v-if="index === 'today' || index === 'yesterday'"
																				>{{ index }}</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else>{{
																				formatDate(index)
																			}}</span>
																		</div>
																	</td>
																</tr>
																<template v-for="rowChild in row">
																	<tr :key="rowChild?.id">
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-center">
																				<v-icon small>mdi-clock-outline</v-icon
																				><span class="font-weight-bold text-h5 ms-1">{{
																					formatTime(rowChild?.start_date)
																				}}</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-baseline">
																				<template v-if="rowChild?.callType == 'call'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="red" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Phone</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'email'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="green" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Email</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'other'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="blue" v-bind="attrs" v-on="on"
																								><v-icon color="white" small
																									>mdi-dots-horizontal-circle-outline</v-icon
																								></v-chip
																							>
																						</template>
																						<span>O</span>
																					</v-tooltip>
																				</template>
																				<div class="font-level-3-bold ms-1">
																					{{ rowChild?.title }}
																				</div>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div v-if="rowChild && rowChild.description" class="text_climp">
																				<span class="d-flex align-baseline">
																					<span class="desc_para" v-html="truncate(rowChild?.description, 20)"></span>
																					<v-btn
																						small
																						text
																						color="cyan"
																						class="font-weight-700"
																						@click="showDescription(rowChild.description)"
																						>Read More</v-btn
																					>
																				</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey text-center">
																			<div class="date d-flex align-items-center showHideAction">
																				<v-btn
																					class="white--text mr-2 rounded-sm"
																					depressed
																					color="blue darken-4"
																					tile
																					v-on:click="reminderComplete(rowChild)"
																				>
																					Mark As Complete
																				</v-btn>
																				<v-btn
																					width="32"
																					min-width="32"
																					class="white--text mr-2 rounded-sm"
																					depressed
																					outlined
																					color="blue darken-4"
																					tile
																					v-on:click="editReminderForm(rowChild.id)"
																					:disabled="isDisabled"
																				>
																					<v-icon>mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					depressed
																					width="32"
																					min-width="32"
																					outlined
																					class="white--text mr-2 rounded-sm"
																					color="red darken-4"
																					tile
																					v-on:click="reminderDel(rowChild)"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</div>
																		</td>
																	</tr>
																</template>
															</template>
														</tbody>
														<tfoot v-else>
															<tr>
																<td colspan="6">
																	<p class="m-0 row-not-found text-center">
																		<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																		Uhh... There are no meetings at the moment.
																	</p>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</v-tab-item>
										<v-tab-item value="upcoming">
											<div class="reminder-rows-container" v-if="false">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTabNew
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTabNew>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no upcoming reminders at the moment.
													</p>
												</template>
											</div>
											<div class="reminder-listing">
												<div class="overflow-y" style="max-height: calc(100vh - 245px)">
													<table
														width="100%"
														class="listing-table table-head-sticky"
														style="border-spacing: 0; border-collapse: initial"
													>
														<thead>
															<tr>
																<th class="p-2 light-blue-bg" width="300">Time</th>
																<th class="p-2 light-blue-bg" width="350">Title</th>
																<th class="p-2 light-blue-bg">Description</th>
																<th class="p-2 light-blue-bg text-center" width="200">Action</th>
															</tr>
														</thead>
														<tbody v-if="reminderData">
															<template v-for="(row, index) in reminderData">
																{{ row.start_date }}

																<tr :key="'date-' + index" v-if="!index == ''" :class="index ? 'active_class' : ''">
																	<td colspan="4" class="p-2 border-top-light-grey">
																		<div>
																			<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon
																			><span
																				class="font-level-2-bold text-capitalize"
																				v-if="index === 'today' || index === 'yesterday'"
																				>{{ index }}</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else>{{
																				formatDate(index)
																			}}</span>
																		</div>
																	</td>
																</tr>
																<template v-for="rowChild in row">
																	<tr :key="rowChild?.id">
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-center">
																				<v-icon small>mdi-clock-outline</v-icon
																				><span class="font-weight-bold text-h5 ms-1">{{
																					formatTime(rowChild?.start_date)
																				}}</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-baseline">
																				<template v-if="rowChild?.callType == 'call'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="red" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Phone</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'email'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="green" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Email</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'other'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="blue" v-bind="attrs" v-on="on"
																								><v-icon color="white" small
																									>mdi-dots-horizontal-circle-outline</v-icon
																								></v-chip
																							>
																						</template>
																						<span>O</span>
																					</v-tooltip>
																				</template>
																				<div class="font-level-3-bold ms-1">
																					{{ rowChild?.title }}
																				</div>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div v-if="rowChild && rowChild.description" class="text_climp">
																				<span class="d-flex align-baseline">
																					<span class="desc_para" v-html="truncate(rowChild?.description, 20)"></span>
																					<v-btn
																						small
																						text
																						color="cyan"
																						class="font-weight-700"
																						@click="showDescription(rowChild.description)"
																						>Read More</v-btn
																					>
																				</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey text-center">
																			<div class="date d-flex align-items-center showHideAction">
																				<v-btn
																					class="white--text mr-2 rounded-sm"
																					depressed
																					color="blue darken-4"
																					tile
																					v-on:click="reminderComplete(rowChild)"
																				>
																					Mark As Complete
																				</v-btn>
																				<v-btn
																					width="32"
																					min-width="32"
																					class="white--text mr-2 rounded-sm"
																					depressed
																					outlined
																					color="blue darken-4"
																					tile
																					v-on:click="editReminderForm(rowChild.id)"
																					:disabled="isDisabled"
																				>
																					<v-icon>mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					depressed
																					width="32"
																					min-width="32"
																					outlined
																					class="white--text mr-2 rounded-sm"
																					color="red darken-4"
																					tile
																					v-on:click="reminderDel(rowChild)"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</div>
																		</td>
																	</tr>
																</template>
															</template>
														</tbody>
														<tfoot v-else>
															<tr>
																<td colspan="6">
																	<p class="m-0 row-not-found text-center">
																		<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																		Uhh... There are no meetings at the moment.
																	</p>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</v-tab-item>
										<v-tab-item value="complate">
											<div class="reminder-rows-container" v-if="false">
												<template v-if="reminderData && reminderData.length > 0">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTabNew
															:key="rerenderKey"
															v-on:editReminder="editReminderForm($event)"
															v-on:editTitle="open_reminder_drawer($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccessNew="refreshReminder($event)"
															v-on:deleteSuccess="deleteRefreshReminder($event)"
														></ReminderTabNew>
													</div>
												</template>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no complete reminders at the moment.
													</p>
												</template>
											</div>
											<div class="reminder-listing">
												<div class="overflow-y" style="max-height: calc(100vh - 245px)">
													<table
														width="100%"
														class="listing-table table-head-sticky"
														style="border-spacing: 0; border-collapse: initial"
													>
														<thead>
															<tr>
																<th class="p-2 light-blue-bg" width="300">Time</th>
																<th class="p-2 light-blue-bg" width="350">Title</th>
																<th class="p-2 light-blue-bg">Description</th>
																<th class="p-2 light-blue-bg text-center" width="200">Action</th>
															</tr>
														</thead>
														<tbody v-if="reminderData">
															<template v-for="(row, index) in reminderData">
																{{ row.start_date }}

																<tr :key="'date-' + index" v-if="!index == ''" :class="index ? 'active_class' : ''">
																	<td colspan="4" class="p-2 border-top-light-grey">
																		<div>
																			<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon
																			><span
																				class="font-level-2-bold text-capitalize"
																				v-if="index === 'today' || index === 'yesterday'"
																				>{{ index }}</span
																			>
																			<span class="font-level-2-bold text-capitalize" v-else>{{
																				formatDate(index)
																			}}</span>
																		</div>
																	</td>
																</tr>
																<template v-for="rowChild in row">
																	<tr :key="rowChild?.id">
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-center">
																				<v-icon small>mdi-clock-outline</v-icon
																				><span class="font-weight-bold text-h5 ms-1">{{
																					formatTime(rowChild?.start_date)
																				}}</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div class="d-flex align-baseline">
																				<template v-if="rowChild?.callType == 'call'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="red" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Phone</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'email'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="green" v-bind="attrs" v-on="on"
																								><v-icon color="white" small>{{ rowChild?.callType }}</v-icon></v-chip
																							>
																						</template>
																						<span>Email</span>
																					</v-tooltip>
																				</template>
																				<template v-else-if="rowChild?.callType == 'other'">
																					<v-tooltip top>
																						<template v-slot:activator="{ on, attrs }">
																							<v-chip class="px-1" label color="blue" v-bind="attrs" v-on="on"
																								><v-icon color="white" small
																									>mdi-dots-horizontal-circle-outline</v-icon
																								></v-chip
																							>
																						</template>
																						<span>O</span>
																					</v-tooltip>
																				</template>
																				<div class="font-level-3-bold ms-1">
																					{{ rowChild?.title }}
																				</div>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey">
																			<div v-if="rowChild && rowChild.description" class="text_climp">
																				<span class="d-flex align-baseline">
																					<span class="desc_para" v-html="truncate(rowChild?.description, 20)"></span>
																					<v-btn
																						small
																						text
																						color="cyan"
																						class="font-weight-700"
																						@click="showDescription(rowChild.description)"
																						>Read More</v-btn
																					>
																				</span>
																			</div>
																		</td>
																		<td class="p-2 border-top-light-grey text-center">
																			<div class="date d-flex align-items-center showHideAction">
																				<v-btn
																					class="white--text mr-2 rounded-sm"
																					depressed
																					color="blue darken-4"
																					tile
																					v-on:click="reminderComplete(rowChild)"
																				>
																					Mark As Complete
																				</v-btn>
																				<v-btn
																					width="32"
																					min-width="32"
																					class="white--text mr-2 rounded-sm"
																					depressed
																					outlined
																					color="blue darken-4"
																					tile
																					v-on:click="editReminderForm(rowChild.id)"
																					:disabled="isDisabled"
																				>
																					<v-icon>mdi-pencil</v-icon>
																				</v-btn>
																				<v-btn
																					depressed
																					width="32"
																					min-width="32"
																					outlined
																					class="white--text mr-2 rounded-sm"
																					color="red darken-4"
																					tile
																					v-on:click="reminderDel(rowChild)"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</div>
																		</td>
																	</tr>
																</template>
															</template>
														</tbody>
														<tfoot v-else>
															<tr>
																<td colspan="6">
																	<p class="m-0 row-not-found text-center">
																		<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																		Uhh... There are no meetings at the moment.
																	</p>
																</td>
															</tr>
														</tfoot>
													</table>
												</div>
											</div>
										</v-tab-item>
									</v-tabs-items>
								</div>
							</v-tab-item>
							<v-tab-item value="stage">
								<div class="">
									<StageTab
										v-if="assetTab == 'stage'"
										class="border"
										type="stage"
										:stageHistoryData="stageData"
									>
									</StageTab>
								</div>
							</v-tab-item>
							<v-tab-item value="file">
								<Files
									v-if="assetTab == 'file'"
									class="border"
									type="lead"
									:url="'lead'"
									:type-id="lead_id"
								>
								</Files>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
				<CreateMeeting
					v-if="open_dialog.meeting"
					:drawer="open_dialog.meeting"
					:title-status="meeting_title"
					:get-meeting-data="editMeetingData"
					:attendies="attendieList"
					v-on:updateMeetingSuccess="refreshMeeting"
					v-on:close="open_dialog['meeting'] = false"
					related="lead"
					:relatedId="lead_id"
				></CreateMeeting>
				<CreateOrUpdateReminderV2
					v-if="open_dialog.reminder"
					:drawer="open_dialog.reminder"
					related="lead"
					:relatedId="lead_id"
					:reminderId="edit_reminder_id"
					v-on:success="refreshReminder"
					v-on:close="closeReminderDialog()"
				>
				</CreateOrUpdateReminderV2>
				<Dialog :dialog="desc_dialog" :dialog-width="600">
					<template v-slot:title> Description</template>
					<template v-slot:body>
						<v-row class="delete-dialog">
							<v-col md="2" class="py-0 text-right my-auto">
								<span class="svg-icon svg-icon-lg delete-confirm-icon">
									<!--begin::Svg Icon-->
									<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
									<!--end::Svg Icon-->
								</span>
							</v-col>
							<v-col md="12" class="py-0 my-auto">
								<div v-html="fullDescription"></div>
							</v-col>
						</v-row>
					</template>
					<template #action>
						<!-- <v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="!formValid || pageLoading"
						depressed
						color="blue darken-4"
						tile
						@click="onSubmit"
					>
						Save
					</v-btn> -->
						<v-btn depressed tile :disabled="pageLoading" @click="desc_dialog = false"> Close </v-btn>
					</template>
				</Dialog>
			</template>
			<template v-slot:footer> </template>
		</DetailTemplate>
		<AcceptedDialog
			v-if="acceptDialog"
			v-on:close="acceptDialog = false"
			:accept-dialog="acceptDialog"
			v-on:success="findOpportunity"
		>
		</AcceptedDialog>
		<RejectedDialog
			v-if="rejectDialog"
			v-on:close="rejectDialog = false"
			:reject-dialog="rejectDialog"
			v-on:success="findOpportunity()"
		></RejectedDialog>
		<FullDialog v-if="leadsDialog" dense :dialog="leadsDialog" content-class="testdata">
			<template #title
				>{{ opportunityData.name }} Notes
				<v-spacer></v-spacer>
				<v-btn icon class="text-white" color="red darken-4" depressed tile @click="closeDialog">
					<v-icon dark left>mdi-close</v-icon>
					Close
				</v-btn>
			</template>
			<template #body>
				<AllNotesTab
					v-if="leadsDialog"
					:relatedId="lead_id"
					:relatedType="'lead-notes'"
					:siggest-list="notesUserArr"
					create-url="lead-note"
					get-url="lead-note"
				>
				</AllNotesTab>
			</template>
			<!-- <template v-slot:action>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialog">
					Close
				</v-btn>
			</template> -->
		</FullDialog>
		<DeleteTemplate
			type="Reminder"
			:deleteUrl="delURL"
			:deleteText="delTEXT"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="
				confirm_dialog = false;
				getReminder();
			"
		></DeleteTemplate>
		<ReminderCompleteTemplate
			type="Reminder"
			:completeUrl="completeURL"
			:completeText="completeTEXT"
			:completeDialog="complete_dialog"
			v-on:close="complete_dialog = false"
			v-on:success="
				complete_dialog = false;
				getReminder();
			"
			:dataObject="remind"
		></ReminderCompleteTemplate>
		<div class="floating-btn-wrapper">
			<div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(243, 165, 165)"></div>
				<v-icon
					@click="open_reminder_drawer('Create')"
					style="font-size: 50px !important"
					color="red"
					small
					>mdi-alarm-check</v-icon
				>
			</div>
			<div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(43, 149, 105)"></div>
				<v-icon style="font-size: 50px !important" color="green" small v-on:click="leadsDialog = true"
					>mdi-chat
				</v-icon>
			</div>
		</div>
	</div>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { QUERY, GET,UPLOAD } from "@/core/services/store/request.module";
// import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AcceptedDialog from "@/view/components/AcceptedDialog";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import UpdateReminderMenu from "@/view/components/UpdateReminderMenu";
import RejectedDialog from "@/view/components/RejectedDialog";
import main from "@/core/mixins/main.mixin";
import ReminderCompleteTemplate from "@/view/components/ReminderCompleteTemplate";
import { SET_MESSAGE } from "@/core/services/store/common.module";

import { toSafeInteger } from "lodash";
export default {
	name: "Opportunity-details",
	watch: {
		rawImage(param) {
			const _this = this;

			_this.uploadAttachment(param).then((response) => {
				_this.profileImage = _this.lodash.head(response);
				//   if (_this.lodash.isEmpty(_this.profileImage) === false) {
				//     _this.$store
				//       .dispatch(PATCH, {
				//         url: "engineer/" + _this.engineer.id,
				//         data: {
				//           profile_logo: _this.profileImage.id,
				//           role: _this.engineer.role.id,
				//         },
				//       })
				//       .catch((error) => {
				//         _this.logError(error);
				//       });
				//   }
			});
		},
	},
	mixins: [main],

	data() {
		return {
			statusTab: 0,
			activeTabClass: "green darken-4 text-white v-tab--active",
			
			rawImage:null,
			company_logo_url: "https://fsm-v3.businessthrust.com/media/default/no-profile-image.png",
			pageloading: null,
			acceptstatus: null,
			apireminder: false,
			stageData: [],
			apistage: false,
			editReminder: false,
			apimeeting: false,
			rejectstatus: null,
			quotationstatus: 0,
			meetingstatus: 0,
			notcontactedstatus: 0,
			primaryLoader:false,
			followstatus: 0,
			rejectDialog: false,
			pageLoading: false,
			negotiationstatus: 0,
			acceptDialog: false,
			diffInDays: 0,
			datadummy: [],
			confirm_dialog: false,
			color1: 0,
			completeURL: null,
			completeTEXT: null,
			delURL: null,
			delTEXT: null,
			rerenderKey: Number(new Date()),
			isDisabled: false,
			single_reminder: null,
			desc_dialog: false,
			fullDescription: "",
			remind: [],
			all_count_meeting: [],
			assetTab: "assetTab",
			stage: [],
			meetingTab: "all",
			open_details: false,
			lead_id: 0,
			meetingData: null,
			meeting_title: null,
			editMeetingData: null,
			attendieList: {},
			reminderData: null,
			complete_dialog: false,
			reminderCounting: null,
			leadsDialog: false,
			leadData: {},
			reminderTabs: "overdue",
			open_dialog: {
				notes: false,
				task: false,
				meeting: false,
				reminder: false,
				add_company: false,
				add_deal: false,
				add_attachment: false,
			},
			dummyList: {
				"2024-06-29": [
					{
						attendees: [
							{
								id: null,
								name: null,
								image: null,
								display_name: null,
							},
						],
						total_batch_reminders: 3,
						id: 7,
						barcode: "RMD-0012",
						batch: "5e7ad5b0-a31c-4b76-86d4-7dd80625db80",
						title: "gfdgdfg",
						start_date: "2024-06-29 00:00:00",
						end_date: "2024-06-30 00:00:00",
						type: 2,
						callType: "other",
						frequency: "3",

						description: null,
						pinned: 0,
						status: 1,
						related_id: 1,
						lead: 0,
						task: 0,
						meeting: 0,
						customer: 0,
						project: 0,
						file: 0,
						related_to: "lead",
						activated: 1,
						user: 1,
						added_by: 1,
						added_at: "2024-07-02 16:51:47",
						updated_by: 0,
						updated_at: null,
						deleted_at: null,
						address1: null,
						address2: null,
						zip_code: null,
						country: null,
						related: null,
						lead_customer_name: null,
						lead_barcode: "BT-0000001",
						lead_date: null,
						task_title: null,
						task_barcode: null,
						meeting_barcode: null,
						customer_barcode: null,
					},
				],
				"2024-06-30": [
					{
						attendees: [
							{
								id: null,
								name: null,
								image: null,
								display_name: null,
							},
						],
						total_batch_reminders: 3,
						id: 8,
						barcode: "RMD-0013",
						batch: "5e7ad5b0-a31c-4b76-86d4-7dd80625db80",
						title: "gfdgdfg",
						start_date: "2024-06-30 00:00:00",
						end_date: "2024-07-01 00:00:00",
						type: 2,
						callType: "other",
						frequency: "3",
						description: null,
						pinned: 0,
						status: 1,
						related_id: 1,
						lead: 0,
						task: 0,
						meeting: 0,
						customer: 0,
						project: 0,
						file: 0,
						related_to: "lead",
						activated: 1,
						user: 1,
						added_by: 1,
						added_at: "2024-07-02 16:51:47",
						updated_by: 0,
						updated_at: null,
						deleted_at: null,
						address1: null,
						address2: null,
						zip_code: null,
						country: null,
						related: null,
						lead_customer_name: null,
						lead_barcode: "BT-0000001",
						lead_date: null,
						task_title: null,
						task_barcode: null,
						meeting_barcode: null,
						customer_barcode: null,
					},
				],
			},
			statusList: [
				{
					id: 2,
					text: "Not Contacted",
					value: "not-contacted",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "purple",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 165,
				},
				{
					id: 3,
					text: "Follow UP",
					value: "follow-up",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "orange",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 118,
				},
				{
					id: 4,
					text: "Meeting",
					value: "meeting",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "pink",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 49,
				},
				{
					id: 5,
					text: "Quotation",
					value: "quotation",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "cyan",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 11,
				},
				{
					id: 6,
					text: "Rejected",
					value: "reject",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 148,
				},
				{
					id: 7,
					text: "Accepted",
					value: "accept",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 22,
				},
			],
			logData: [],
			opportunityData: {
				// barcode: "BT-0000498",
				// company_name: "Abc Corp",
				// product_type: "Seo",
				// created_date: "01/03/2024",
				// contact: "",
				// contact_email: "info@gmail.com",
				// contact_number: "+65 1234 567",
				// company: "",
				// company_email: "abc@gmail.com",
				// company_number: "+65 1234 5678",
				// display_name: "admin",
				// remark:
				// 	"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam purus nisi, mattis quis laoreet eget, auctor eu metus. Etiam a mi ut leo cursus porta. Vivamus ac pulvinar eros, ut aliquam dolor. Quisque risus orci, placerat eget dapibus et",
				// description:
				// 	"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam purus nisi, mattis quis laoreet eget, auctor eu metus. Etiam a mi ut leo cursus porta. Vivamus ac pulvinar eros, ut aliquam dolor. Quisque risus orci, placerat eget dapibus et",
				// address: "72 Bendemeer Rd, #07-03 Luzerne S(), 28 Lor 23 Geylang, 00074, Singapore, 11002",
				// last_contact_formated: "06/06/2024",
				// opportunity: "High-Chance",
				// meeting: "Business Related",
				// meeting_date: "06/06/2024",
				// meeting_time: "9:30 AM",
				// reminder_date: "05/06/2024",
			},
			reminderDataList: [],
		};
	},
	components: {
		DetailTemplate,
		Dialog,
		ReminderCompleteTemplate,
		DeleteTemplate,
		UpdateReminderMenu,
		StageTab: () => import("@/view/components/StageTab"),
		Files: () => import("@/view/components/Files"),
		ActivityTab: () => import("@/view/pages/leads/components/ActivityTab"),
		ActivityTabNew: () => import("@/view/pages/leads/components/ActivityTabNew"),
		MeetingTabNew: () => import("@/view/pages/leads/components/MeetingTabNew"),
		ReminderTabNew: () => import("@/view/pages/leads/components/ReminderTabNew"),
		CreateMeeting: () => import("@/view/pages/leads/create/CreateMeeting"),
		CreateOrUpdateReminderV2: () => import("@/view/pages/leads/create/CreateOrUpdateReminderV2"),
		FullDialog: () => import("@/view/components/FullDialog"),
		AllNotesTab: () => import("@/view/pages/leads/components/NotesTab3"),
		RejectedDialog,
		AcceptedDialog,
	},
	methods: {
		selectImage() {
			this.$refs["uploadImage"].$refs["input"].click();
		},
		uploadAttachment(file) {
			const _this = this;
			return new Promise((resolve, reject) => {
				try {
					_this.primaryLoader = true;
					let formData = new FormData();
					formData.append("files[0]", file, file.name);
					formData.append("parent_id", this.lead_id);
					formData.append("module", "opportunity");

					_this.$store
						.dispatch(UPLOAD, { url: "upload-lead ", data: formData })
						.then((response) => {
							console.log(response);
							this.company_logo_url = response;
						})
						.catch((error) => {
							reject(error);
						})
						.finally(() => {
							_this.primaryLoader = false;
						});
				} catch (error) {
					reject(error);
				}
			});
		},
		getStage() {
			this.id = this.$route.params.id;
			this.$store
				.dispatch(GET, {
					url: `find-stage/${this.id}`,
				})
				.then((data) => {
					this.stageData = data;
					console.log(data, "data");
					// this.all_count_meeting = data?.dbMeetingQuery;

					// this.open_dialog.reminder = false;
					// this.rerenderKey = Number(new Date());
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		handleTabChange(type) {
			if (type == "meeting") {
				if (!this.apimeeting) {
					this.getMeeting();
					this.apimeeting = true;
				}
			} else if (type == "reminder") {
				if (!this.apireminder) {
					this.getReminder();
					this.apireminder = true;
				}
			} else if (type == "stage") {
				if (!this.apistage) {
					this.getStage();
					this.apistage = true;
				}
			}
		},
		updateStatus(status) {
			const _this = this;
			if (status != this.opportunityData.status) {
				this.$store
					.dispatch(QUERY, {
						url: `change-opportunity-stage`,
						data: {
							status,
							id: this.$route.params.id,
							type: "status",
						},
					})
					.then((data) => {
						//   _this.reservation_status = data;
						_this.$store.commit(SET_MESSAGE, [{ model: true, message: "Updated Successfully." }]);
						this.$emit("reload:content", true);
						this.findOpportunity();
						this.rerenderKey = Number(new Date());

						console.log(data, "_this.customer_email");

						/*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
 console.log(_this.purchaseOrder.supplier.barcode)     */
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {});
			}
		},

		reminderDel(row) {
			this.delTEXT = `(${row.barcode}) ` + row.title;
			this.delURL = `reminder/${row.id}`;
			this.confirm_dialog = true;
			/* this.$emit("reminderSuccess", row);
			this.$emit("reminderSuccessNew", row);
			 this.$emit("deleteSuccess", row); */
		},
		reminderComplete(remind) {
			console.log(remind, "remind");
			this.completeTEXT = `(${remind.barcode}) ` + remind?.title;
			this.completeURL = `reminder-complete/${remind.id}`;
			this.complete_dialog = true;
		},

		changeColor(type = "") {
			if (!type) return;
			if (type == "accept") {
				this.acceptDialog = true;
				this.isOppStatus = true;
				this.isAccepted = true;
				this.isRejected = true;
				// this.activeTabClass = "green lighten-5 text-dark v-tab--active";
			}
			if (type == "reject") {
				this.rejectDialog = true;
				this.isOppStatus = true;
				// this.isRejected = true;
				// this.isAccepted = false;
				// this.activeTabClass = "red lighten-5 text-dark v-tab--active";
			}
			if (type == "reopen") {
				this.isOppStatus = false;
				// this.activeTabClass = "green darken-4 text-white v-tab--active";
			}
		},
		checkdateReminder() {
			const singleMeetingDate = new Date(this.single_reminder?.start_date);
			const currentDate = new Date();

			if (singleMeetingDate < currentDate) {
				this.color1 = 1;
			} else {
				console.log("The meeting date is in the future.");
			}
		},
		editMeeting(id) {
			this.open_dialog.meeting = true;
			this.$store
				.dispatch(GET, { url: `single-meeting/${id}` })
				.then((data) => {
					this.editMeetingData = data.meeting;

					this.attendieList = data.attendie[0];
					console.log(this.editMeetingData, "editMeetingData");
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getMeeting(type) {
			this.$store
				.dispatch(QUERY, {
					url: `meeting-lead`,
					data: { related_to: "lead", leadId: this.lead_id, status: type ? type : 10 },
				})
				.then((data) => {
					this.meetingData = data?.data_meeting;
					this.all_count_meeting = data?.dbMeetingQuery;

					this.open_dialog.reminder = false;
					this.rerenderKey = Number(new Date());
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		findOpportunity() {
			this.id = this.$route.params.id;

			this.$store
				.dispatch(GET, {
					url: `find-single-opportunity/${this.id}`,
				})

				.then((response) => {
					this.opportunityData = response?.deal; // Assuming data is in response.data
					this.logData = response?.logs; // Assuming data is in response.data

					this.single_reminder=response?.reminders;
					this.followstatus=response?.followstatus;
					this.meetingstatus=response?.meetingstatus;
					this.quotationstatus=response?.quotationstatus;
					this.notcontactedstatus=response?.notcontactedstatus;
					this.negotiationstatus=response?.negotiationstatus;
					this.diffInDays=response?.diffInDays;
					this.stage = response?.results; // Assuming data is in response.data
					this.company_logo_url = response?.deal?.company_logo_url; // Assuming data is in response.data

					
                             this.editReminder =false;

					this.checkdateReminder();

					if (response?.deal?.dealproduct) {
						this.opportunity.product_type = response.deal.dealproduct.map(
							(product_type) => product_type.product_id
						);
					}
					if (this.opportunityData) {
						if (this.opportunityData?.statusTab) {
							this.statusTab = this?.opportunityData?.statusTab;
						}
						if (this.opportunityData?.status == "accept") {
							this.activeTabClass = "green lighten-5 text-dark v-tab--active";
						} else if (this.opportunityData?.status == "reject") {
							this.activeTabClass = "red lighten-5 text-dark v-tab--active";
						}else{
						
							this.activeTabClass = "green darken-4 text-white v-tab--active";
						}
					}

					// if(this.opportunityData?.status=='accept'){
					// 	this.activeTabClass = "green lighten-5 text-dark v-tab--active";

					// }else if(this.opportunityData?.status=='reject'){
					// 	this.activeTabClass = "red lighten-5 text-dark v-tab--active";

					// }
					this.rerenderKey = Number(new Date());
                     this.getStage();

				}


					// this.contact.tags = response?.data[0]?.tag;
					// this.contact.company = response?.data[0]?.company?.id;
				)
				.catch((error) => {
					console.error("Error fetching contact:", error);
				});
		},
		truncate(text, length) {
			return text.length > length ? text.substring(0, length) + "..." : text;
		},
		showDescription(description) {
			this.fullDescription = description;
			this.desc_dialog = true;
		},
		open_meeting_drawer(name) {
			this.editMeetingData = {};
			this.attendieList = {};
			this.open_dialog.meeting = true;
			this.meeting_title = name;
		},
		open_reminder_drawer(name) {
			this.editReminderData = {};
			this.attendieList = {};
			this.open_dialog.reminder = true;
			this.meeting_title = name;
		},
		closeReminderDialog() {
			this.open_dialog.reminder = false;
			this.edit_reminder_id = 0;
		},
		refreshMeeting() {
			this.getMeeting();
		},
		goBackData() {
			const filterQuery = this.$route.query;
			console.log("filterQuery => ", filterQuery);
			this.$router.push({
				name: "opportunity",
				query: {
					...filterQuery,
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},
		editReminderForm(id) {
			this.open_dialog.reminder = true;
			this.edit_reminder_id = id;
		},
		deleteRefreshReminder(row) {
			this.getReminder(row.status);
		},
		refreshReminder(row) {
			console.log({ update: row });
			this.getReminder(row.status);
			this.findOpportunity();
			// if(row.status_marked && row.status_marked =="complete"){
			// 	this.reminderTabs = "complate";
			// }
		},
		closeDialog() {
			this.leadsDialog = false;
		},
		getReminder(type) {
			//this.pageLoading = true;
			if (!type) {
				this.reminderTabs = "overdue";
				type = 1;
			}
			this.$store
				.dispatch(QUERY, {
					url: `reminder-list`,
					data: { related_id: this.lead_id, related_to: "lead", status: type ? type : 1 },
				})
				.then((data) => {
					if (type == 1) {
						this.reminderTabs = "overdue";
					} else if (type == 2) {
						this.reminderTabs = "upcoming";
					} else if (type == 3) {
						this.reminderTabs = "complate";
					}
					this.reminderData = data.rows;

					this.reminderCounting = data.countings ? data.countings : {};
					this.rerenderKey = Number(new Date());

					//this.singleLead();
					//console.log(this.reminderData );
					//this.pageLoading = false;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
	mounted() {
		this.datadummy = [this.dummyList];
		// if (!this.getPermission("opportunity:view") && this.lead_id > 0) {
		// 	this.$router.replace({
		// 		name: "opportunity",
		// 		query: { t: new Date().getTime() },
		// 		date: [],
		// 	});
		// }

		// this.singleLead();
		// this.getActivity();
		this.findOpportunity();
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Opportunity", disabled: false, href: "/Opportunity" },
			{ text: "Detail", disabled: true },
		]);

		this.getReminder();
		this.getMeeting();
		// this.getStage();
		// this.getTasks();
		// this.getCompanyList();
		// this.getCompanyDealList();
		// this.getLeadAttachment();
		this.viewType = this.$route.query.viewType;
	},
	beforeMount() {
		this.lead_id = toSafeInteger(this.$route.params.id);
	},
};
</script>
<style scoped>
.meeting_tab .v-tabs .v-tab--active.blue-tab {
	background-color: #0d47a1 !important;
	color: white;
}
.meeting_tab .v-tabs .blue-tab {
	border: 1px solid #0d47a1 !important;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.meeting_tab .v-tabs .v-tab--active.grey-tab {
	background-color: grey !important;
	color: white;
}
.meeting_tab .v-tabs .grey-tab {
	border: 1px solid grey !important;
}
.meeting_tab .v-tabs .v-tab--active.blue-tab2 {
	background-color: blue !important;
	color: white;
}
.meeting_tab .v-tabs .blue-tab2 {
	border: 1px solid blue !important;
}
.meeting_tab .v-tabs .v-tab--active.green-tab {
	background-color: green !important;
	color: white;
}
.meeting_tab .v-tabs .green-tab {
	border: 1px solid green !important;
}

.meeting_tab .v-tabs .v-tab--active.red-tab {
	background-color: red !important;
	color: white;
}
.meeting_tab .v-tabs .red-tab {
	border: 1px solid red !important;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.reminder_tab .v-tabs .v-tab--active.red-tab {
	background-color: red !important;
	color: white;
}
.reminder_tab .v-tabs .red-tab {
	border: 1px solid red !important;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.reminder_tab .v-tabs .blue-tab {
	border: 1px solid #0d47a1 !important;
}
.reminder_tab .v-tabs .v-tab--active.blue-tab {
	background-color: #0d47a1 !important;
	color: white;
}
.reminder_tab .v-tabs .green-tab {
	border: 1px solid green !important;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.reminder_tab .v-tabs .v-tab--active.green-tab {
	background-color: green !important;
	color: white;
}
.showHideAction {
	opacity: 0;
}
.reminder-listing table tbody tr:hover .showHideAction {
	opacity: 1;
}
</style>
